import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TicTacToe from './components/tictactoc/TicTacToe';
import NavBar from './components/navbar/NavBar';
import NotFound from './Pages/NotFound';
import Home from './Pages/Home';
import Footer from './components/footer/Footer';

function App() {
  return (
 <div>
  <BrowserRouter>
  <NavBar/>
  <Routes>
    <Route path="/" element={<Home/>} />
   
    <Route path="/tictactoe" element={<TicTacToe/>} />
    <Route path="*" element={<NotFound/>} />
  </Routes>
  <Footer/>
  </BrowserRouter>
  
 </div>
  );
}

export default App;
