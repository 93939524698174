import React from 'react'
import Hero from '../components/hero/Hero'
import GameSection from '../components/gameSection/GameSection'

const Home = () => {
  return (
    <div>
      <Hero/>
      <hr className='text-white' />
      <h1 className=' text-center mt-1 text-uppercase'>Most Popular Game</h1>
      <GameSection/>
    </div>
  )
}

export default Home
