import React, { useRef, useState } from 'react';
import "./TicTacToe.css";
import circle_icon from "../Assets/TicTacToe/circle.png";
import cross_icon from "../Assets/TicTacToe/cross.png";
import winAudio from "../Assets/TicTacToe/win.mp3";
import drawAudio from "../Assets/TicTacToe/draw.mp3";

let data = ['', '', '', '', '', '', '', '', ''];

const TicTacToe = () => {
  const [count, setCount] = useState(0);
  const [lock, setLock] = useState(false);
  const titleRef = useRef(null);
  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);
  const box7 = useRef(null);
  const box8 = useRef(null);
  const box9 = useRef(null);
  const winAudioRef = useRef(new Audio(winAudio));
  const drawAudioRef = useRef(new Audio(drawAudio));

  const box_array = [box1, box2, box3, box4, box5, box6, box7, box8, box9];

  const toggle = (e, num) => {
    if (lock) {
      return;
    }
    if (data[num] !== '') {
      return; // Prevent overwriting
    }
    if (count % 2 === 0) {
      e.target.innerHTML = `<img src='${cross_icon}'>`;
      data[num] = 'x';
      setCount((prevCount) => prevCount + 1);
    } else {
      e.target.innerHTML = `<img src='${circle_icon}'> `;
      data[num] = 'o';
      setCount((prevCount) => prevCount + 1);
    }
    checkwin();
  };

  const checkwin = () => {
    const winPatterns = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let pattern of winPatterns) {
      const [a, b, c] = pattern;
      if (data[a] && data[a] === data[b] && data[a] === data[c]) {
        won(data[a]);
        return;
      }
    }

    if (!data.includes('')) {
      titleRef.current.innerHTML = 'Match Draw';
      drawAudioRef.current.play();
      setLock(true);
    }
  };

  const won = (winner) => {
    setLock(true);
    if (winner === 'x') {
      titleRef.current.innerHTML = `Congratulations: <img src='${cross_icon}'>`;
      winAudioRef.current.play();
    } else if (winner === 'o') {
      titleRef.current.innerHTML = `Congratulations: <img src='${circle_icon}'> `;
      winAudioRef.current.play();
    }
  };

  const resetGame = () => {
    setLock(false);
    data = ['', '', '', '', '', '', '', '', ''];
    titleRef.current.innerHTML = 'Tic Tac Toe';
    box_array.forEach((box) => {
      box.current.innerHTML = '';
    });
    setCount(0); // Reset the move count
  };

  return (
    <div className="p-4 text-center bg-dark border-top">
      <h1 className="my-5 text-white" ref={titleRef}>
        Tic Tac Toe <span className="text-info">Game</span>
      </h1>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex">
          <div className="box border border-dark rounded m-1" ref={box1} onClick={(e) => toggle(e, 0)}></div>
          <div className="box border border-dark rounded m-1" ref={box2} onClick={(e) => toggle(e, 1)}></div>
          <div className="box border border-dark rounded m-1" ref={box3} onClick={(e) => toggle(e, 2)}></div>
        </div>
        <div className="d-flex">
          <div className="box border border-dark rounded m-1" ref={box4} onClick={(e) => toggle(e, 3)}></div>
          <div className="box border border-dark rounded m-1" ref={box5} onClick={(e) => toggle(e, 4)}></div>
          <div className="box border border-dark rounded m-1" ref={box6} onClick={(e) => toggle(e, 5)}></div>
        </div>
        <div className="d-flex">
          <div className="box border border-dark rounded m-1" ref={box7} onClick={(e) => toggle(e, 6)}></div>
          <div className="box border border-dark rounded m-1" ref={box8} onClick={(e) => toggle(e, 7)}></div>
          <div className="box border border-dark rounded m-1" ref={box9} onClick={(e) => toggle(e, 8)}></div>
        </div>
      </div>
      <button className="btn btn-info mt-4" onClick={resetGame}>
        Reset Game
      </button>
    </div>
  );
};


export default TicTacToe;
