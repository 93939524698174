import React from 'react';

import tictac_logo from '../Assets/TicTacToe/tictac_logo.jpg'; 
import Coming_Soon from '../Assets/GameSection/coming_soon_logo.jpg'
import { Link } from 'react-router-dom';

const TicTacToeHeader = () => {
  return (
    <div className='container mt-1'>
      <div class="row ">
  <div class="col-lg-3 col-md-6 d-none d-md-block  mt-1">
    <div class="card">
      <div class="card-body">
        <img src={tictac_logo} alt='TicTacToe-Logo'  className='img-fluid' />
        <h5 class="card-title mt-3">Tic-Tac-Toe        </h5>
        <p class="card-text">The game is played on a grid that's 3 squares by 3 squares.</p>
        <Link to="/tictactoe" class="btn btn-primary">Play Now</Link>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 d-none d-md-block  mt-1 disabled">
    <div class="card ">
      <div class="card-body">
        <img src={Coming_Soon} alt='TicTacToe-Logo'  className='img-fluid mt-2' />
        <h5 class="card-title mt-3"> Comming Soon   </h5>
        <p class="card-text">We’re thrilled to announce that our latest game is coming soon!</p>
        <Link to="#" class="btn btn-primary disabled ">Coming Soon...</Link>
      </div>
    </div>
  </div>
  

 


      {/*  small screens */}
      <div className='d-md-none d-sm-block'>
        <div className='container py-3'>
          <div className="row justify-content-center align-items-center">
            <div className='d-flex w-100 justify-content-between align-items-center bg-white rounded p-2'>
              <div className='d-flex align-items-center'>
                <img src={tictac_logo} className='rounded-circle' width={50} alt='tictactoe' />
                <h2 className='mt-2 ms-2'>Tic Tac Toe</h2>
              </div>
              <div>
                <Link to='/tictactoe' className='btn btn-primary'>Play Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TicTacToeHeader;
