import React from 'react'
import game_logo from '../Assets/Navbar/game-land.svg'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div>
    
<footer className="text-center text-lg-start bg-body-tertiary text-muted mt-3">
  
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
   
    <div className="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
    <div>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-facebook-f" />
      </Link>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-twitter" />
      </Link>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-google" />
      </Link>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-instagram" />
      </Link>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-linkedin" />
      </Link>
      <Link to="#!" className="me-4 text-reset">
        <i className="fab fa-github" />
      </Link>
    </div>
   
  </section>
  <section className>
    <div className="container text-center text-md-start mt-5">
     
      <div className="row mt-3">

        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          
          <h6 className="text-uppercase fw-bold mb-4">
            <img src={game_logo} alt='GameLand_Logo'width={50} />Game land
          </h6>
          <p>
          Whether you're into action-packed adventures, brain-teasing puzzles, or relaxing casual games, Gameland has something for everyone.
          </p>
        </div>
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 className="text-uppercase fw-bold mb-4">
          USEFUL LINKS
          </h6>
          <p>
            <Link to="#!" className=" text-dark fw-medium text-decoration-none ">Home</Link>
          </p>
          <p>
            <Link to="#!" className="text-dark fw-medium text-decoration-none">Features</Link>
          </p>
          <p>
            <Link to="#!" className="text-dark fw-medium text-decoration-none">Pricing</Link>
          </p>
          <p>
            <Link to="#!" className="text-dark fw-medium text-decoration-none">FAQs</Link>
          </p>
          <p>
            <Link to="#!" className="text-dark fw-medium text-decoration-none">About</Link>
          </p>
        </div>
    
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
        
          <h6 className="text-uppercase fw-bold mb-4">
            Quick Links
          </h6>
          <p>
            <Link to="#!" className=" fw-medium text-dark text-decoration-none">Games</Link>
          </p>
          <p>
            <Link to="#!" className="fw-medium text-dark text-decoration-none">Pages</Link>
          </p>
          <p>
            <Link to="#!" className="fw-medium text-dark text-decoration-none">Articles</Link>
          </p>
          <p>
            <Link to="#!" className="fw-medium text-dark text-decoration-none">Reviews</Link>
          </p>
          <p>
            <Link to="#!" className="fw-medium text-dark text-decoration-none">Settings</Link>
          </p>
        </div>
       
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          {/* Links */}
          <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
      <p>
            <i className="fas fa-envelope me-1" />
        <span className='text-dark'>    aqibbuttportfolio@gmail.com</span>
          </p>
        </div>
       
      </div>
     
    </div>
  </section>
  <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
  © 2024-2025 Gameland™. All Rights Reserved By Aqibbuttportfolio
  </div>
</footer>

    </div>
  )
}

export default Footer
